import { api } from "./client";

async function downloadFile(url, filename) {
    const { data } = await api.get(url, { responseType: "blob" });
    var blob      = new Blob([ data ]);
    var link      = document.createElement("a");
    link.href     = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
}

export { downloadFile }
<template>
    <b-container>
        <b-card>
            <router-link :to="{ name: 'signup_settings', params: { id: id } }" class="mr-2">
                <b-icon-arrow-left-circle scale="1.5" class="mr-2" />
                Back
            </router-link>
            <h4 class="text-center">
                Sign-up List
            </h4>
            <div class="d-flex justify-content-center mt-4">
                <button
                    class="sc-btn pill mr-2"
                    @click="download()"
                >
                    <font-awesome-icon icon="download" class="mr-2" />
                    Download Sign-up List
                </button>
                <router-link
                    class="sc-btn pill"
                    :to="{ name: 'signup_members', params: { id: id } }"
                >
                    <font-awesome-icon icon="user" class="mr-2" />
                    View List by Member
                </router-link>
            </div>
        </b-card>
        <b-table
            :key="show.join(',')"
            class="mt-4"
            :busy="isBusy"
            :fields="fields"
            :items="getMembers"
            :filter="filter"
            :no-provider-filtering="true"
            :no-provider-sorting="true"
            empty-text="There are no results that match your criteria."
            empty-filtered-text="There are no results that match your criteria."
            head-variant="dark"
            bordered
        >
            <template #cell(action)="row">
                <div class="float-right">
                    <b-button
                        variant="link"
                        @click="confirmDelete(row.item)"
                    >
                        <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                    </b-button>
                </div>
            </template>
        </b-table>
    </b-container>
</template>
<script>
import { base_url } from "@/api/getBaseUrl"
import { downloadFile } from "@/api/downloadFile"
import { mapState } from 'vuex';
const types = {
    unknown: "Unknown",
    student: "Student",
    parent:  "Parent"
};
const genders = {
    unknown: "Unknown",
    M: "Male",
    F: "Female"
}
export default {
    props: ["id"],
    data() {
        return {
            isBusy: false,
            show  : [1],
            count : {},
            filter: "",
            fields: [
                { key: "last_name", label: "Last Name", sortable: true },
                { key: "first_name", label: "First Name", sortable: true },
                { key: "age", label: "Age", sortable: true },
                { key: "gender", label: "Gender", sortable: true, formatter: (value) => genders[value] || "Unknown" },
                { key: "type", label: "Type", sortable: true, formatter: (value) => types[value] || "Unknown" },
                { key: "contact_email", label: "Contact Email", sortable: true },
                { key: "is_other", label: "Other", sortable: true },
                { key: "date_submitted", label: "Sign up Date", formatter: (value) => {
                    return this.$moment(value).local().format("LLLL");
                }, sortable: true }
            ]
        };
    },
    methods: {
        formatOtherList(other_list) {
            return (JSON.parse(other_list || "[]") || []).join(", ");
        },
        getMembers() {
            return this.$api.get(
                `/api/sign-ups/admin/${this.id}/responses.json`,
                { params: { status: this.show } }
            ).then((response) => response.data).catch(() => ([]));
        },
        download() {
            downloadFile(
                `${base_url}/api/sign-ups/admin/${this.id}/responses.csv`,
                `${this.group.group_name}-signup-${this.id}-responses.csv`
            );
        }
    },
    computed: mapState(["group"])
}
</script>